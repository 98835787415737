import Swiper from 'swiper/bundle';
import ScrollReveal from 'scrollreveal';


//トップページ　キービジュアルスライダー
export function idxFunc() {
  //スライド
  const swiper = new Swiper('#paper-slide', {
    loop: true,
    autoplay: {
      delay: 5000,
    },
    speed: 1000,
    // centeredSlides: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 50,
    navigation: {
      nextEl: '#swiper-button-next--paper-slide',
      prevEl: '#swiper-button-prev--paper-slide',
    }
  });

  const illustSwiper = new Swiper('#illust-slide', {
    loop: true,
    autoplay: {
      delay: 5000,
    },
    speed: 1000,
    // centeredSlides: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 100,
    navigation: {
      nextEl: '#swiper-button-next--illust-slide',
      prevEl: '#swiper-button-prev--illust-slide',
    }
  });

  $('.voice__more-wrapper').on('click', function() {
    $(this).prev('.slide-content').slideToggle();
    $(this).toggleClass('open');
    
    if ($(this).find('.voice__more').text() === '続きを読む') {
      $(this).find('.voice__more').text('閉じる');
    } else {
      $(this).find('.voice__more').text('続きを読む');
    }
  });

    //scroll
    var sldeUp = { easing: 'ease', duration: 500, distance: '50px', scale: 1 };
    ScrollReveal().reveal('.anm', sldeUp );  
}
