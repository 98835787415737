export function headerFunc() {
  // ハンバーガーメニュー実装
  $('.navToggle').on('click', function() {
    $('.navToggle').toggleClass('closeNav');
    $('.headerNav').toggleClass('openNav');
  });
}

export function headerScrFunc() {
  console.log('スクロールで呼ばれます。');
}